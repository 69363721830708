
















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

function postForm(
  path: string,
  params: { [key: string]: any },
  method?: "post" | "get"
) {
  method = method || "post";
  const form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);
  form.setAttribute("target", "_blank");
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);

      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
}

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      loaded: false,
      showPassword: false,
      site: {},
      apiAddress: "https://api.kongresy2.com.pl/api",
      eventId: root.$route.params.id,
    });

    const model = reactive({
      data: {
        domain: "",
        wordpressPassword: "",
        wordpressEmail: "",
        databaseName: "",
        wordpressUser: "",
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`website/${root.$route.params.id}/wordpress`)
        .then(({ data: { wordpressWebsiteData } }) => {
          model.data = wordpressWebsiteData.wordpressWebsite;
          state.site = wordpressWebsiteData.wordpressWebsite;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const goToAdmin = () => {
      postForm(`https://${model.data.domain}/wp-login.php`, {
        log: model.data.wordpressEmail,
        pwd: model.data.wordpressPassword,
        redirect_to: `https://${model.data.domain}/wp-admin/`,
      });
    };

    return { state, model, goToAdmin };
  },
});
